import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ModalDismissReasons,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/core/services/alert.service';
import {
  AttachmentDetails,
  AttachmentList,
  CustomPdfParams,
} from 'src/app/modules/memos/model/memo.model';
import { MemoService } from 'src/app/modules/memos/service/memo.service';
import { ThemeList, ThemeService } from '../../service/theme.service';

@Component({
  selector: 'app-custom-download-pdf',
  templateUrl: './custom-download-pdf.component.html',
  styleUrls: ['./custom-download-pdf.component.scss'],
})
export class CustomDownloadPdfComponent implements OnChanges {
  @Input() showPopup = false;
  @Input() memoID: number | null = null;
  @Input() memoNumber = 'download';
  @ViewChild('stickyNotePopup') stickyNotePopup: NgbModalRef;
  @Output() dismiss = new EventEmitter();

  modal: NgbModalRef;

  themeList: ThemeList;

  splitPDF = false;
  selectAll = false;
  isLoading = false;
  putStickyOnPdf = true;

  relatedDocuments: AttachmentList;

  checkBoxes = {
    originalDocument: true,
    allComments: true,
    allStickies: true,
    allAttachments: true,
    referencedDocuments: true,
    filesInCommentBoxes: true,
  };

  openRelatedDocs = {
    originalDocument: false,
    allAttachments: false,
    referencedDocuments: false,
    filesInCommentBoxes: false,
  };

  translations = {
    originalDocument: 'DOWNLOAD-PDF.ORGINAL-DOCUMENT',
    allComments: 'DOWNLOAD-PDF.ALL-COMMENT',
    allAttachments: 'DOWNLOAD-PDF.ALL-FILE',
    allStickies: 'DOWNLOAD-PDF.ALL-STICKIES',
    referencedDocuments: 'DOWNLOAD-PDF.REFERENCES',
    filesInCommentBoxes: 'DOWNLOAD-PDF.COMMENT-FILE',
  };

  constructor(
    private alert: AlertService,
    private modalService: NgbModal,
    private themeService: ThemeService,
    private memoService: MemoService,
  ) {
    this.themeService.data.subscribe((theme) => {
      this.themeList = theme;
    });
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (
      simpleChanges.showPopup &&
      simpleChanges.showPopup.currentValue
    ) {
      this.showDownLoadPopup();
      this.checkBoxes = {
        originalDocument: true,
        allComments: true,
        allStickies: true,
        allAttachments: true,
        referencedDocuments: true,
        filesInCommentBoxes: true,
      };
      this.putStickyOnPdf = true;
      this.getRelatedData();
    }
  }

  getRelatedData(): void {
    if (!this.memoID) return;
    const param = { memo: this.memoID?.toString() };
    this.memoService.getMemoAttachmentFile(param).subscribe({
      next: (data) => {
        data = {
          ...data,
          original_documents: data.original_documents.map((each) => {
            each.checked = true;
            return each;
          }),
          attachment: data.attachment.map((each) => {
            each.checked = true;
            return each;
          }),
          reference: data.reference.map((each) => {
            each.checked = true;
            return each;
          }),
          comment: data.comment.map((each) => {
            each.checked = true;
            return each;
          }),
        };
        this.relatedDocuments = data;
        this.checkBoxes.originalDocument =
          !!data.original_documents.length;
        this.checkBoxes.allAttachments = !!data.attachment.length;
        this.checkBoxes.referencedDocuments = !!data.reference.length;
        this.checkBoxes.filesInCommentBoxes = !!data.comment.length;
        this.checkBoxes.allComments = !!data.has_comment;
        this.checkBoxes.allStickies = !!data.has_sticky_notes;
        // if (!data.has_sticky_notes) {
        //   this.putStickyOnPdf = false;
        // }
        if (this.relatedDocuments.original_documents.length > 1) {
          this.openRelatedDocs.originalDocument = true;
        }
        if (this.relatedDocuments.comment.length > 1) {
          this.openRelatedDocs.filesInCommentBoxes = true;
        }
        const allPropertiesTrue =
          this.checkBoxes.originalDocument &&
          this.checkBoxes.allComments &&
          this.checkBoxes.allStickies &&
          this.checkBoxes.allAttachments &&
          this.checkBoxes.referencedDocuments &&
          this.checkBoxes.filesInCommentBoxes;
        this.selectAll = allPropertiesTrue;
      },
      error: (error) => {
        this.alert.error(
          error?.error?.detail ||
            error?.error ||
            error?.status ||
            'Error',
        );
      },
    });
  }

  showDownLoadPopup(): void {
    this.modal = this.modalService.open(this.stickyNotePopup, {
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
  }

  dismissedPopup(): void {
    this.modal?.close();
    this.dismiss.emit();
  }

  get documentList(): string[] {
    return Object.keys(this.checkBoxes);
  }

  toggleStickyOnPdf(): void {
    this.putStickyOnPdf = !this.putStickyOnPdf;
  }

  toggleCheck(title: string): void {
    this.checkBoxes[title] = !this.checkBoxes[title];
    switch (title) {
      case 'originalDocument':
        this.relatedDocuments.original_documents.forEach(
          (each) => (each.checked = this.checkBoxes[title]),
        );
        break;
      case 'allAttachments':
        this.relatedDocuments.attachment.forEach(
          (each) => (each.checked = this.checkBoxes[title]),
        );
        break;
      case 'filesInCommentBoxes':
        this.relatedDocuments.comment.forEach(
          (each) => (each.checked = this.checkBoxes[title]),
        );
        break;
      case 'referencedDocuments':
        this.relatedDocuments.reference.forEach(
          (each) => (each.checked = this.checkBoxes[title]),
        );
        break;
    }
    this.checkSelectedAll();
  }

  canExpand(title: string): boolean {
    return Object.keys(this.openRelatedDocs).includes(title);
  }

  toggleExpand(title: string): void {
    this.openRelatedDocs[title] = !this.openRelatedDocs[title];
  }

  relatedDocs(title: string): AttachmentDetails[] {
    let list: AttachmentDetails[] = [];
    switch (title) {
      case 'originalDocument':
        list = this.relatedDocuments.original_documents;
        break;
      case 'allAttachments':
        list = this.relatedDocuments.attachment;
        break;
      case 'filesInCommentBoxes':
        list = this.relatedDocuments.comment;
        break;
      case 'referencedDocuments':
        list = this.relatedDocuments.reference;
        break;
    }
    return list;
  }

  toggleCheckDoc(file: AttachmentDetails, title: string): void {
    file.checked = !file.checked;
    this.checkBoxes[title] = this.relatedDocs(title).every(
      (each) => each.checked,
    );
    this.checkSelectedAll();
  }

  downloadPDF(): void {
    const dataForBE: CustomPdfParams = {
      include_original_memo: this.checkBoxes.originalDocument,
      include_sticky_note_on_pdf: this.putStickyOnPdf,
      include_comment: this.checkBoxes.allComments,
      include_sticky_note: this.checkBoxes.allStickies,
      include_comment_file_ids: this.filterAndChange(
        this.relatedDocuments.comment,
      ),
      include_reference_file_ids: this.filterAndChange(
        this.relatedDocuments.reference,
      ),
      include_attachment_file_ids: this.filterAndChange(
        this.relatedDocuments.attachment,
      ),
      include_upload_file_ids: [],
      split_pdf: this.splitPDF,
    };
    this.isLoading = true;

    if (this.checkSelectedOnlyOriginalDoc(dataForBE)) {
      this.memoService.downloadMemo(this.memoID).subscribe({
        next: (blob: Blob) => {
          this.downloadFileFromBlob(blob, `${this.memoNumber}.pdf`);
          this.isLoading = false;
        },
        error: (error) => {
          this.alert.error(
            error?.statusText ||
              error?.message ||
              error?.status ||
              'Error',
          );
        },
      });
      return;
    }

    this.memoService
      .customDownloadPDf(this.memoID?.toString(), dataForBE)
      .subscribe({
        next: (blob) => {
          const fielName = `${this.memoNumber}${
            this.splitPDF ? '.zip' : '.pdf'
          }`;
          this.downloadFileFromBlob(blob, fielName);
          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
          this.alert.error(
            error?.statusText ||
              error?.message ||
              error?.status ||
              'Error',
          );
        },
      });
  }

  downloadFileFromBlob(
    blob: Blob,
    fileName = 'downloaded.pdf',
  ): void {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  filterAndChange(relatedFiles: AttachmentDetails[]): number[] {
    return relatedFiles
      .filter((each) => each.checked || false)
      .map((each) => each.value);
  }

  selectAllDocs(): void {
    this.selectAll = !this.selectAll;
    Object.keys(this.checkBoxes).forEach((eachTitle) => {
      this.checkBoxes[eachTitle] = this.selectAll;
      if (
        this.canExpand(eachTitle) &&
        this.openRelatedDocs[eachTitle]
      ) {
        const relatedFiles = this.relatedDocs(eachTitle);
        relatedFiles.forEach((eachFile) => {
          eachFile.checked = this.selectAll;
        });
      }
    });
  }

  checkSelectedAll(): void {
    this.selectAll = Object.values(this.checkBoxes).every(
      (each) => each === true,
    );
  }

  checkSelectedOnlyOriginalDoc(params: CustomPdfParams): boolean {
    if (!params.include_original_memo) {
      return false;
    }

    let checkedCount = 0;
    for (const value of Object.values(params)) {
      const isChecked = Array.isArray(value)
        ? value?.length > 0
        : !!value;
      if (isChecked) {
        checkedCount++;
      }
    }
    return checkedCount === 1;
  }
}
