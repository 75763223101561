import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { GetUserById } from 'src/app/store/auth/auth.actions';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(private store: Store) {}
  checkAccess(roles: string[], checkType: string) {
    if (checkType === 'role') {
      if (
        !this.store.selectSnapshot<string>(
          (state) => state.auth?.role,
        )
      ) {
        this.store.dispatch(GetUserById);
      }
      const role = this.store.selectSnapshot<string>(
        (s) => s.auth.role,
      );
      return roles.includes(role);
    } else {
      const permissions = this.store.selectSnapshot<string[]>(
        (s) => s.auth.permissions,
      );
      // TODO: next step if have more action can_ in this project need to adjust return index value
      return roles.includes(permissions[0]);
    }
  }
}
