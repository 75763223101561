<div class="pdf-view">
  <div class="page-control w-100 justify-content-between align-items-center">
    <div class="col-lg-3 col-md-3 col-5">
      <svg-icon src="assets/images/icons/view-2.svg"
                ngbTooltip="{{'MEMOS.PREVIEW' | translate}}"
                container="body"
                class="pointer mr-2"
                [svgStyle]="{'width.px': 16, 'height.px': 16}"
                (click)="clickPreview.emit()">
      </svg-icon>
      <button class="d-inline btn btn-icon mx-1 sticky-note-btn"
        (click)="addNewStickyNote()">
        <img src="assets/svg/icon-usage/sticky-note.svg"
          width="16"
          height="16"
          alt="add sticky note">
      </button>
    </div>
    <div class="col-auto row no-gutters">
      <svg-icon src="assets/images/icons/back2.svg"
        class="pointer"
        [svgStyle]="{ 'width.px': 25, 'height.px': 25 }"
        style="width: 20px;height: 25px;margin-right: 10px;"
        (click)="changeDocumentPageBack()">
      </svg-icon>
      <div ngbDropdown
           class="d-inline-block"
           autoClose="true">
        <span>{{"UPLOAD.PAGE"|translate}}</span>
        <button class="btn page-btn mx-1"
                ngbDropdownToggle>{{currentDocumentPage}}</button>
        <span>{{"UPLOAD.OF"|translate}}&nbsp;{{totalDocumentPage}}</span>
        <div ngbDropdownMenu>
          <ng-container *ngFor="let page of documentPages">
            <div style="margin: 5px;">
              <button ngbDropdownItem
                [ngClass]="{'active': page === currentDocumentPage}"
                (click)="changeDocumentPage(page)">{{page}}</button>
            </div>
          </ng-container>
        </div>
      </div>
      <svg-icon src="assets/images/icons/next2.svg"
        class="pointer"
        [svgStyle]="{ 'width.px': 25, 'height.px': 25 }"
        style="width: 20px;height: 25px;margin-left: 10px;"
        (click)="changeDocumentPageNext()">
      </svg-icon>
    </div>
    <div *ngIf="showDownload"
        class="col-2 d-none d-md-block icon pointer p-1 text-center"
        (click)="showDownloadPDF()">
      <svg-icon src="assets/images/icon_download2.svg"
          [svgStyle]="{'width.px': 13, 'height.px': 13}"
          [style.--c]="themeList?.header_table_color"></svg-icon>
      <span [style.color]="themeList?.header_table_color"
          class="underline ml-2">{{ 'MEMOS.DOWNLOAD'|translate }}</span>
    </div>
  </div>

  <div *ngIf="!pdf"
      class="pdf-shadow bg-color d-flex w-100 page-view justify-content-center align-items-center">
    <div class="custom-pdf-view">
      <div class="spinner-center ">
        <app-loading-spinner></app-loading-spinner>
      </div>
    </div>
  </div>

  <div class="overflow-container"
       [ngClass]="{'is-approval': isApproval}">
    <div class="pdf-shadow bg-color"
      [ngClass]="checkPage ==='approvalEmailPage' ? 'page-view-email' : 'page-view'">
      <div class="on-top-container">
        <app-sticky-note class="w-100 h-100"
                        [person]="person"
                        [loginUserID]="loginUserID"
                        [ownerName]="stickyNoteOwnerName"
                        [currentPage]="currentDocumentPage"
                        (reloadData)="getStickyNotes($event)"
                        [memoID]="memoID"
                        #stickyNote>
        </app-sticky-note>
      </div>
      <div class="p-md-4 p-2">
        <div class="custom-pdf-view"
            #pdfView
            (resized)="pageRendered()">
          <pdf-viewer class="h-100"
            [src]="{url: pdfFile, httpHeaders: httpHeaders}"
            [ngStyle]="{'height': height + 'px', 'width': 'auto'}"
            [(page)]="currentDocumentPage"
            [ngClass]="{'pdf-viewer-scrollbar-gutter-stable': scrollbarGutterStable}"
            [render-text]="true"
            [render-text-mode]="1"
            [original-size]="false"
            [autoresize]="true"
            [stick-to-page]="false"
            [fit-to-page]="true"
            [show-all]="false"
            [show-borders]="false"
            (page-rendered)="pageRendered()"
            (after-load-complete)="onLoadPdfComplete($event)">
        </pdf-viewer>
        </div>
      </div>
    </div>
  </div>
</div>


<app-custom-download-pdf [showPopup]="showStickyNotePopupDownload"
                         [memoID]="memoID"
                         [memoNumber]="memoNumber"
                         (dismiss)="showStickyNotePopupDownload = false">
</app-custom-download-pdf>
