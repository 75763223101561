import { CdkDragStart } from '@angular/cdk/drag-drop';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ResizedEvent } from 'angular-resize-event';
// eslint-disable-next-line max-len
import { CoordinatePosition } from 'src/app/modules/memos/components/upload-memos/upload-template-customize/upload-template-customize.component';
import {
  BACKGROUND_COLOR_LIST,
  FONT_LIST,
  SIGNATURE_COLOR_LIST,
} from 'src/app/modules/memos/service/upload-memo.constants';
import { featureFlag } from 'src/environments/environment';
import * as _ from 'lodash';
import { generateUUID } from '../../utils/common.util';
import {
  CreateStickyNoteIdentity,
  StickyNote,
  StickyNoteIdentity,
  UpdateStickyNoteIdentity,
} from 'src/app/modules/memos/components/upload-memos/form-inputs/form-inputs.model';
import { Store } from '@ngxs/store';
import { User } from '../../models/user.models';
import { AlertService } from 'src/app/core/services/alert.service';
import { Subscription } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { StickyNoteService } from '../../service/sticky-note.service';

@Component({
  selector: 'app-sticky-note',
  templateUrl: './sticky-note.component.html',
  styleUrls: ['./sticky-note.component.scss'],
})
export class StickyNoteComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  @Input() memoID: number = null;
  @Input() loginUserID: number = null;
  @Input() ownerName = '';
  @Input() allowEdit = false;
  @Input() currentPage = 1;
  @Input() isApprovalRequest = false;
  @Input() person;
  @Output() reloadData = new EventEmitter();

  @ViewChild('pdfViewer') pdfViewer: ElementRef;

  stickyNotes: StickyNoteIdentity[] = [];

  textDateType: string;
  selectedID = '';
  selectedStickyNoteID = '';

  showStickyNote = false;
  loadingStickies = false;

  modal: NgbModalRef = null;

  fontList = FONT_LIST;
  signatureColorList = SIGNATURE_COLOR_LIST;
  backgroundColorList = BACKGROUND_COLOR_LIST;

  readonly fhdMarkerDefaultWidth = 216;
  readonly fhdMarkerDefaultHeight = 52.5;
  readonly DRAGDROP_DEFAULT_FONTSIZE = 1.8796992481203008;
  readonly DRAGDROP_DEFAULT_WIDTH = this.fhdMarkerDefaultWidth / 1025;
  readonly DRAGDROP_DEFAULT_HEIGHT =
    this.fhdMarkerDefaultHeight / 1450;

  pdfElementWidth: number;
  pdfElementHeight: number;

  profile: User = null;
  currentMarker: StickyNoteIdentity;
  preDragPosition: CoordinatePosition;
  deletingStickyNote: StickyNoteIdentity | null = null;

  originalPageView: { width: number; height: number } = null;

  stickyNotesSubscription: Subscription;
  selectedStockyNoteSubscription: Subscription;
  stickyNoteLayerVisibilitySubscription: Subscription;

  constructor(
    private store: Store,
    private alert: AlertService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
    public translate: TranslateService,
    private stickyNoteService: StickyNoteService,
  ) {
    this.store.subscribe((state) => {
      this.profile = state.auth;
    });
    this.stickyNotesSubscription =
      this.stickyNoteService.$stickyNotes.subscribe((data) => {
        this.performDataManipulation(data);
      });
    this.selectedStockyNoteSubscription =
      this.stickyNoteService.$selectedStickyNoteID.subscribe((id) => {
        this.selectedStickyNoteID = id;
      });
    this.stickyNoteLayerVisibilitySubscription =
      this.stickyNoteService.$showStickyNoteLayer.subscribe(
        (show) => {
          this.showStickyNote = show;
        },
      );
  }

  ngOnInit(): void {
    if (!this.stickyNoteLayerVisibilitySubscription) {
      this.stickyNoteService.$showStickyNoteLayer.next(true);
    }
  }

  ngOnDestroy(): void {
    this.stickyNoteService.$showStickyNoteLayer.next(true);
    this.stickyNotesSubscription.unsubscribe();
    this.selectedStockyNoteSubscription.unsubscribe();
    this.stickyNoteLayerVisibilitySubscription.unsubscribe();
  }

  ngAfterViewChecked(): void {
    if (!this.originalPageView) {
      this.pdfElementWidth = this.pdfViewer.nativeElement.offsetWidth;
      this.pdfElementHeight =
        this.pdfViewer.nativeElement.offsetHeight;

      this.originalPageView = {
        width: this.pdfViewer.nativeElement.offsetWidth,
        height: this.pdfViewer.nativeElement.offsetHeight,
      };
    }
    this.cdr.detectChanges();
  }

  setWidthAndHeight(): void {
    this.refreshScreenPosition();
  }

  dragdropGroupTrackByFunc(
    _index: number,
    item: StickyNoteIdentity,
  ): StickyNoteIdentity {
    return item;
  }

  dragdropTrackByFunc(
    _index: number,
    item: StickyNoteIdentity,
  ): string {
    return item.id;
  }

  async performDataManipulation(data: StickyNote[]): Promise<void> {
    this.stickyNotes = await data.map((each) => {
      each.position.stickyNoteId = each.id;
      each.position.ownerID = each.created_user;
      each.position.name = each.created_user_name || '';
      each.position.date = each.updated_at || '';
      each.position.string = each.note;
      each.position.enableSetting = false;
      return each.position;
    });
    this.loadingStickies = false;
    this.refreshScreenPosition();
  }

  getPositionFilterByPage(
    markerPositions: StickyNoteIdentity[],
  ): StickyNoteIdentity[] {
    const pageFiltered = markerPositions?.filter(
      (position) => position?.page === this.currentPage,
    );
    if (featureFlag.self_service_form) {
      return pageFiltered;
    } else {
      return pageFiltered?.filter(
        (position) => !position?.formInputType,
      );
    }
  }

  convertToScreenMarkerWidth(
    markerIdentity: StickyNoteIdentity,
  ): number {
    return (
      (markerIdentity.width ||
        markerIdentity.W / this.originalPageView.width ||
        this.DRAGDROP_DEFAULT_WIDTH) * this.pdfElementWidth
    );
  }

  onPdfViewResized(event: ResizedEvent): void {
    if (event.newWidth !== event.oldWidth) {
      this.pdfElementWidth = event.newWidth;
    }
    if (event.newHeight !== event.oldHeight) {
      this.pdfElementHeight = event.newHeight;
    }
    this.originalPageView = {
      width: this.pdfElementWidth,
      height: this.pdfElementHeight,
    };
    this.refreshScreenPosition();
  }

  async refreshScreenPosition(markerIDToEdit = ''): Promise<void> {
    await this.stickyNotes?.map((markerIdentity) => {
      markerIdentity.screenCoordinate = this.convertToScreenPos({
        x: markerIdentity.X,
        y: markerIdentity.Y,
      });
      return markerIdentity;
    });
    const changedSticky = this.stickyNotes.filter(
      (each) => each.id === markerIDToEdit,
    )[0];
    if (changedSticky && changedSticky.stickyNoteId) {
      this.changedPosition(changedSticky);
    }
  }

  convertToScreenPos(
    position: CoordinatePosition,
  ): CoordinatePosition {
    return {
      x: position.x * this.pdfElementWidth * 0.01 || 0,
      y: position.y * this.pdfElementHeight * 0.01 || 0,
    };
  }

  get currentWidthScale(): number {
    try {
      return this.pdfElementWidth / this.originalPageView.width;
    } catch (error) {
      console.error(error);
    }
  }

  get filterStickyNotesByPage(): StickyNoteIdentity[] {
    return this.stickyNotes.filter(
      (each) => each.page === this.currentPage,
    );
  }

  getFontStyle(style: { name: string; value: string }): string {
    if (!style) {
      return this.fontList[0].value;
    }
    return style.value;
  }

  canEditOrDelete(stickyNote: StickyNoteIdentity): boolean {
    if (this.allowEdit) {
      return true;
    }

    return (
      stickyNote.ownerID === this.profile.id ||
      stickyNote.ownerID === this.loginUserID ||
      stickyNote.name === this.person?.full_name
    );
  }

  onDragStart(dragdrop: StickyNoteIdentity): void {
    this.preDragPosition = this.getMarkerPosition(dragdrop);
  }

  getMarkerPosition(
    markerIdentity: StickyNoteIdentity,
  ): CoordinatePosition {
    return { x: markerIdentity.X, y: markerIdentity.Y };
  }

  onChange(targetIdentity: StickyNoteIdentity, event: any): void {
    this.onDragEnded(event, targetIdentity);
  }

  onDragEnded(
    event: CdkDragStart,
    targetIdentity: StickyNoteIdentity,
  ): void {
    const transform = event.source.getFreeDragPosition();
    this.applyPosition(transform, targetIdentity);
  }

  applyPosition(
    coordinate: CoordinatePosition,
    markerIdentity: StickyNoteIdentity,
    isRelativeCoordinate = false,
  ): void {
    let relativePosition = coordinate;
    if (!isRelativeCoordinate) {
      relativePosition = this.convertTransformToRelativePos(
        coordinate.x,
        coordinate.y,
      );
    }
    markerIdentity.initialPos = coordinate;
    markerIdentity.X =
      relativePosition.x < 0 ? 0 : relativePosition.x;
    markerIdentity.Y =
      relativePosition.y < 0 ? 0 : relativePosition.y;
    markerIdentity.screenCoordinate = coordinate;
    this.refreshScreenPosition(markerIdentity.id);
  }

  convertTransformToRelativePos(
    transformX: number,
    transformY: number,
  ): { x: number; y: number } {
    return {
      x: (transformX / this.pdfElementWidth) * 100,
      y: (transformY / this.pdfElementHeight) * 100,
    };
  }

  addNewStickyNote(): void {
    if (!this.showStickyNote) {
      return;
    }
    const generatedID = generateUUID();
    const identity: StickyNoteIdentity = {
      H: this.convertToOriginalHeight(this.DRAGDROP_DEFAULT_HEIGHT),
      W: this.convertToOriginalWidth(
        this.DRAGDROP_DEFAULT_WIDTH,
        true,
      ),
      X: 0,
      Y: 0,
      id: generatedID,
      page: this.currentPage,
      color: this.signatureColorList[0],
      backgroundColor: this.backgroundColorList[0],
      width: this.DRAGDROP_DEFAULT_WIDTH,
      height: this.DRAGDROP_DEFAULT_HEIGHT,
      string: '',
      name:
        this.ownerName ||
        `${this.profile.first_name} ${this.profile.last_name}`,
      ownerID: this.profile.id,
      date: '',
      fontSize: this.DRAGDROP_DEFAULT_FONTSIZE,
      pageList: [],
      fontStyle: this.fontList[0],
      initialPos: { x: 0, y: 0 },
      dateCustomBE: '',
      optionalType: 'positions',
      enableSetting: false,
      colorForRender: this.convertColorToRenderFormat(
        this.signatureColorList[0],
      ),
      pageIdList: [{ page: this.currentPage, id: generatedID }],
      screenCoordinate: { x: 0, y: 0 },
    };
    this.selectedID = generatedID;
    this.stickyNotes.push(identity);
  }

  convertColorToRenderFormat(color: string): {
    r: number;
    g: number;
    b: number;
  } {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
      color,
    );
    return result
      ? {
          r: parseInt(result[1], 16) / 255,
          g: parseInt(result[2], 16) / 255,
          b: parseInt(result[3], 16) / 255,
        }
      : null;
  }

  convertToOriginalHeight(
    elementHeight: number,
    isRelative = false,
  ): number {
    if (isRelative) {
      return elementHeight * this.originalPageView.height;
    }
    return elementHeight / this.currentHeightScale;
  }

  get currentHeightScale(): number {
    try {
      return this.pdfElementWidth / this.originalPageView.width;
    } catch (error) {
      console.error(error);
    }
  }

  convertToOriginalWidth(
    elementWidth: number,
    isRelative = false,
  ): number {
    if (isRelative) {
      return elementWidth * this.originalPageView.width;
    }
    return elementWidth / this.currentWidthScale;
  }

  submit(marker: StickyNoteIdentity): void {
    delete marker.ownerID;
    if (marker.stickyNoteId) {
      const id = marker.stickyNoteId;
      delete marker.stickyNoteId;
      const data = {
        position: {
          ...marker,
        },
      };
      this.updateStickies(id, data);
      return;
    }
    const data: CreateStickyNoteIdentity = {
      memo: this.memoID,
      position: {
        ...marker,
      },
    };
    this.createStickies(data);
  }

  textAreaAdjust(element: HTMLTextAreaElement) {
    element.style.height = '1px';
    element.style.height = element.scrollHeight + 'px';
  }

  getTextAreaHeight(element: HTMLTextAreaElement): string {
    return element.scrollHeight + 'px';
  }

  selectStickyNote(note: StickyNoteIdentity): void {
    const id = note.id === this.selectedStickyNoteID ? '' : note.id;
    this.stickyNoteService.$selectedStickyNoteID.next(id);
  }

  onClickDragDropSetting(marker: StickyNoteIdentity): void {
    marker.enableSetting = !marker.enableSetting;
    this.selectedID = marker.enableSetting ? marker.id : '';
    this.stickyNoteService.$selectedStickyNoteID.next(
      this.selectedStickyNoteID === marker.id ? '' : marker.id,
    );
  }

  getOptionPopupWindowPosition(
    markerIdentity: StickyNoteIdentity,
    dragdropDiv: HTMLDivElement,
  ): {
    top: string;
    left: string;
    bottom: string;
    right: string;
  } {
    const Y_THRESHOLD = this.pdfElementHeight * 0.7; //  70% of pdf height used for toolbox fix above pdf document
    const x_init = markerIdentity.initialPos.x;
    const y_init = markerIdentity.initialPos.y;
    const w_dragdrop = dragdropDiv.offsetWidth;
    const h_dragdrop = dragdropDiv.offsetHeight;

    const position = {
      left: undefined,
      right: undefined,
      bottom: undefined,
      top: undefined,
    };
    const percent_w = w_dragdrop / this.pdfElementWidth; //find percent size of watermark size for pdf
    //watermark 60% for pdf
    if (percent_w > 0.6 || x_init <= 0) {
      position.left = `${w_dragdrop + 5}px`;
    } else if (y_init + h_dragdrop > Y_THRESHOLD) {
      position.bottom = `${h_dragdrop + 5}px`;
    } else {
      position.top = `${h_dragdrop + 5}px`;
    }

    return position;
  }

  isFormInputType(): boolean {
    return false;
  }

  onSelectedColor(
    markerIdentity: StickyNoteIdentity,
    color: string,
  ): void {
    markerIdentity.color = color;
    markerIdentity.colorForRender =
      this.convertColorToRenderFormat(color);
  }

  onSelectedBgColor(
    markerIdentity: StickyNoteIdentity,
    color: string,
  ): void {
    markerIdentity.backgroundColor = color;
  }

  onChangeFontStyle(
    markerIdentity: StickyNoteIdentity,
    event: any,
  ): void {
    markerIdentity.fontStyle = event;
  }

  onChangeFontSize(
    markerIdentity: StickyNoteIdentity,
    sizePx: number,
  ): void {
    markerIdentity.fontSize = (sizePx / this.pdfElementHeight) * 100;
  }

  toggleCustomColor(marker: StickyNoteIdentity) {
    marker.showCustomColor = !marker.showCustomColor;
  }

  changedPosition(marker: StickyNoteIdentity): void {
    delete marker.ownerID;
    if (marker.stickyNoteId) {
      const id = marker.stickyNoteId;
      delete marker.stickyNoteId;
      const data = {
        position: {
          ...marker,
        },
      };
      this.updateStickies(id, data);
    }
  }

  openDeleteConfirmation(
    view: TemplateRef<NgbModalRef>,
    note: StickyNote,
  ): void {
    this.deletingStickyNote = JSON.parse(JSON.stringify(note));
    this.modal = this.modalService.open(view, {
      centered: true,
      size: 'md',
      backdrop: 'static',
      keyboard: false,
    });
  }

  dismissDelete(): void {
    this.deletingStickyNote = null;
    this.modal.dismiss();
  }

  deleteStickyNote(): void {
    if (!this.deletingStickyNote?.stickyNoteId) {
      this.dismissDelete();
      this.reloadData.emit();
      return;
    }
    this.loadingStickies = true;

    this.stickyNoteService
      .deleteStickyNote(this.deletingStickyNote.stickyNoteId)
      .subscribe({
        next: () => {
          this.dismissDelete();
          this.alert.success(
            this.translate.instant('MEMOS.SAVE-CHANGES'),
          );
          this.reloadData.emit();
        },
        error: (error) => {
          this.alert.error(
            typeof error.error === 'object'
              ? error.error.detail
              : error.error[0],
          );
        },
      });
  }

  createStickies(data: CreateStickyNoteIdentity): void {
    this.loadingStickies = true;
    this.stickyNoteService.createStickNote(data).subscribe({
      next: () => {
        this.alert.success(
          this.translate.instant('MEMOS.SAVE-CHANGES'),
        );
        this.selectedID = '';
        this.reloadData.emit();
      },
      error: (error) => {
        this.selectedID = '';
        if (typeof error.error === 'object') {
          this.alert.error(error.error.detail);
        } else {
          this.alert.error(error.error[0]);
        }
        console.error(error);
      },
    });
  }

  updateStickies(id: number, data: UpdateStickyNoteIdentity): void {
    this.loadingStickies = true;
    this.stickyNoteService.updateStickyNote(id, data).subscribe({
      next: () => {
        this.alert.success(
          this.translate.instant('MEMOS.SAVE-CHANGES'),
        );
        this.selectedID = '';
        this.reloadData.emit();
      },
      error: (error) => {
        this.selectedID = '';
        if (typeof error.error === 'object') {
          this.alert.error(error.error.detail);
        } else {
          this.alert.error(error.error[0]);
        }
        console.error(error);
      },
    });
  }
}
